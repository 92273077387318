<template>
  <div>
    <h1 class="mb-0 mt-2">Iniciar Sesión</h1>
    <SignIn1Form email="" password="" document_number="" />
  </div>
</template>

<script>
import auth0 from 'auth0-js'
import SignIn1Form from './forms/SignInForm'
import constant from '../../../config/constant'

export default {
  components: { SignIn1Form },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'mini.dashboard.home-2' })
    }
  },
  methods: {
    loginOAuth0: function () {
      new auth0.WebAuth(constant.auth0Config).authorize()
    }
  }
}
</script>
