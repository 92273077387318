<template>
	<ValidationObserver ref="form" v-slot="{ handleSubmit }">
		<form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
			<ValidationProvider vid="type" name="Tipo" rules="required" v-slot="{ errors }">
				<div class="form-group">
					<label for="typeInput">Tipo</label>
					<select
						:class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
						id="typeInput"
						v-model="user.type"
						required
					>
						<option>Admin</option>
						<option>Doctor/Paciente</option>
					</select>
					<div class="invalid-feedback">
						<span>{{ errors[0] }}</span>
					</div>
				</div>
			</ValidationProvider>

			<ValidationProvider
				v-if="user.type === 'Admin'"
				vid="email"
				name="E-mail"
				rules="required|email"
				v-slot="{ errors }"
			>
				<div class="form-group">
					<label for="emailInput">Correo Electrónico</label>
					<input
						type="email"
						:class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
						id="emailInput"
						aria-describedby="emailHelp"
						v-model="user.email"
						placeholder="Ingrese correo electrónico"
						required
					/>
					<div class="invalid-feedback">
						<span>{{ errors[0] }}</span>
					</div>
				</div>
			</ValidationProvider>

			<ValidationProvider
				v-else
				vid="email"
				name="Número de Documento"
				rules="required"
				v-slot="{ errors }"
			>
				<div class="form-group">
					<label for="documentInput">Número de Documento</label>
					<input
						type="text"
						:class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
						id="documentInput"
						aria-describedby="documentemailHelp"
						v-model="user.document_number"
						placeholder="Ingrese número de documento"
						required
					/>
					<div class="invalid-feedback">
						<span>{{ errors[0] }}</span>
					</div>
				</div>
			</ValidationProvider>

			<ValidationProvider
				vid="password"
				name="Password"
				rules="required"
				v-slot="{ errors }"
			>
				<div class="form-group">
					<label for="passwordInput">Contraseña</label>
					<router-link to="/auth/password-reset" class="float-right">
						¿Has olvidado tu contraseña?
					</router-link>
					<input
						type="password"
						:class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')"
						id="passwordInput"
						v-model="user.password"
						placeholder="Password"
						required
					/>
					<div class="invalid-feedback">
						<span>{{ errors[0] }}</span>
					</div>
				</div>
			</ValidationProvider>
			<div class="d-inline-block w-100">
				<button type="submit" class="btn btn-primary float-right">Iniciar sesión</button>
			</div>
			<!-- <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          ¿No tienes una cuenta?
          <router-link
            to="/dark/auth/sign-up"
            class="iq-waves-effect pr-4"
            v-if="$route.meta.dark"
          >
            Inscribirse
          </router-link>
          <router-link to="/auth/sign-up" class="iq-waves-effect pr-4" v-else>
            Inscribirse
          </router-link>
        </span>
      </div> -->
		</form>
	</ValidationObserver>
</template>

<script>
import { mapStores } from "pinia";
import { useSettingsStore } from "./../../../../stores/settings";
import { useUserStore } from "./../../../../stores/user";
import api from "./../../../../services/api";
import { Toast } from "../../../../mixins/toast";

export default {
	name: "SignIn1Form",
	props: ["email", "password", "document_number"],
	data: () => ({
		user: {
			type: "Admin",
			document_number: "",
			email: "",
			password: "",
		},
	}),
	mounted() {
		this.user.email = this.$props.email;
		this.user.password = this.$props.password;
		this.user.document_number = this.$props.document_number;
	},
	computed: {
		...mapStores(useSettingsStore, useUserStore),
	},
	methods: {
		onSubmit() {
			this.login();
		},
		async login() {
			try {
				const { data } = await api.post("/login", this.user);
				const { user, token, permissions } = data.data;

				localStorage.setItem("isLoggedIn", true);
				localStorage.setItem("user", JSON.stringify(user));
				localStorage.setItem("token", token);
				localStorage.setItem("permissions", JSON.stringify(permissions));

				const roleMapping = {
					Doctor: "dashboard.home-4",
					Paciente: "dashboard.home-3",
				};

				const defaultRoute = roleMapping[user.role] || "dashboard.home-1";
				this.$router.push({ name: defaultRoute });
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
	},
};
</script>
